<template lang="pug">
.recipe-slider
  .recipe-slider--container
    .recipe-slider--title(v-if="title" :style="style") {{ title }}
    slider(
      v-if="data.length"
      :options="options"
    )
      .recipe-slider--wrapper
        recipe-card.recipe-slider--slide.slider-slide(
          v-for="(slide, index) in recipes"
          :key="index"
          :link="`/recepten/${slide.slug}`"
          :title="slide.name"
          :image="slide.thumb_image"
          :persons="slide.persons"
          :preparation-time="slide.preparation_time"
          :favorite="slide.favorite"
          @toggle-favorite="toggleFavorite(slide)"
        )
    nuxt-link.recipe-slider--button.button.gold(to="/recepten") Snel recepten vinden
</template>

<script>
import { textColorMixin } from '@/mixins/colorMixin';
import { enhancedEcommerce } from '@/mixins/enhancedEcommerce';

export default {
  name: 'RecipeSlider',
  components: {
    slider: () => import('@/components/slider'),
    'recipe-card': () => import('@/components/recipes/recipeCard'),
  },
  mixins: [
    textColorMixin,
    enhancedEcommerce,
  ],
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    block: {
      type: Object,
      default: () => {},
    },
    slidesPerView: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      options: {
        wrapperClass: 'recipe-slider--wrapper',
        slideClass: 'recipe-slider--slide',
        slidesPerView: 'auto',
        slidesOffsetBefore: 20,
        spaceBetween: 20,
        breakpoints: {
          940: {
            slidesPerView: this.slidesPerView,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
          },
        },
      },
    };
  },
  computed: {
    favoritedRecipes() {
      return this.$store.getters['api/favoritedRecipesData'] || [];
    },
    recipes() {
      return this.data.map((recipe) => ({
        ...recipe,
        favorite: this.favoritedRecipes.some((favorite) => favorite.id === recipe.id),
      }));
    },
    style() {
      if (!this.block) return '';
      return this.headerTextColor(this.block.header_text_color);
    },
  },
  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch('api/getFavoritedRecipes');
    }
  },
  methods: {
    async toggleFavorite(recipe) {
      const action = recipe.favorite ? 'api/deleteFavoritedRecipe' : 'api/addFavoritedRecipe';

      this.$notification({
        message: `Recept is succesvol ${recipe.favorite ? 'verwijderd' : 'toegevoegd'} uit je favorieten.`,
        type: 'success',
      });

      await this.$store.dispatch(action, { recipeId: recipe.id });

      this.gtmPush({
        event: 'recipeInteraction',
        eventCategory: 'recept',
        eventAction: recipe.favorite ? 'favoriet verwijderen' : 'favoriet maken',
        eventValue: recipe.name,
      });

      this.$store.dispatch('api/getFavoritedRecipes');
    },
  },
};
</script>

<style lang="scss">
$navigationButtonSize: 70px;

.recipe-slider {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px 0;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -40px;
    left: 0;
    z-index: 1;
    height: 40px;
    background-image: url('~assets/images/grudges/grudge-white.png');
    background-size: cover;
    transform: rotate(180deg);
  }

  &--wrapper {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 0 50px 0;
  }

  &--container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    text-align: center;
  }

  &--title {
    margin: 35px auto 70px auto;
    color: $hunterGreen;
    font-weight: 400;
    font-size: 40px;
    font-family: $fontBodoni;
    line-height: 28px;
    box-sizing: border-box;
  }
  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    padding: 0 0 40px 0;

    &::before {
      content: unset;
    }

    &::after {
      content: unset;
    }

    &--wrapper {
      width: calc(100% - 20px);
    }

    &--container {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    &--title {
      width: 100%;
      margin: 30px 0;
      padding: 0 20px;
      font-size: 30px;
      line-height: 38px;
      text-align: left;
    }
  }
}
</style>
